body {
  color: #8e8e93;
  direction: ltr;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased !important;
  background: #f0f3f4;
  line-height: 1.42857143;
}

html {
  -webkit-tap-highlight-color: transparent;
}

/* Bug fix: Prevent the ability to click a span within a button when the button is disabled */
button:disabled span {
  pointer-events: none;
}

.btn {
  -webkit-transition: .3s!important;
  transition: .3s!important;
  background-image: none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  outline: 0!important;
  position: relative;
  border-radius: 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}


.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #58748b;
}

a, a:active, a:focus, a:hover {
  outline: 0!important;
  text-decoration: none!important;
  cursor: pointer;
}
a {
  background-color: transparent;
}

.clip-check input[type=checkbox] {
  opacity: 0;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
  position: absolute;
  margin-top: 4px\9;
  margin-left: -20px;
}

.clip-check {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
}
.clip-check label {
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px;
  padding-left: 30px!important;
  position: relative;
  line-height: 23px;
  -webkit-transition: border .2s linear,color .2s linear;
  transition: border .2s linear,color .2s linear;
  white-space: nowrap;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  color: #858585;
}





input[type=checkbox], input[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  line-height: normal;
}
input[type=checkbox], input[type=radio] {
  margin: 4px 0 0;
  margin-top: 1px\9;
}
input[type=checkbox], input[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}


